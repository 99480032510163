export default defineNuxtRouteMiddleware((to: any, from: any) => {
  const appResourceStore = useAppResourceStore();
  const { $gsap: gsap } = useNuxtApp();
  const { emit } = useEventBus();
  function performTransition() {
    const tl = gsap.timeline();
    if (appResourceStore.oldRouteVal === to.path) {
      appResourceStore.oldRouteVal = to.path;
      gsap.to(".generic__mount__domain", 0, {
        opacity: 0,
      });
      gsap.to(".overlay-rap", 0, {
        height: "100dvh",
        display: "flex",
        opacity: 1,
      });
      tl.to(".overlay-bar", 0, {
        width: "100%",
      })
        .to(".overlay-bar", 0, {
          transform: "translateX(100%)",
        })
        .to(
          ".overlay-bar",
          0.9,
          {
            delay: 0.1,
            transform: "translateX(0)",
            stagger: {
              amount: 0.5,
            },
            ease: "power4.inOut",
          },
          "=-0.3"
        )
        .to(".generic__mount__domain", 0.4, {
          opacity: 1,
        })
        .to(
          ".overlay-bar",
          0.9,
          {
            delay: 0.1,
            width: 0,
            stagger: {
              amount: 0.5,
            },
            ease: "power4.inOut",
          },
          "=-1"
        )
        .to(".overlay-rap", 0, {
          height: 0,
          opacity: 0,
          // transform: "scale(0)",
          display: "none",
        });
    } else {
      appResourceStore.oldRouteVal = from.path;
      gsap.to(".generic__mount__domain", 0, {
        opacity: 0,
      });
      gsap.to(".overlay-rap", 0, {
        height: "100dvh",
        display: "flex",
        opacity: 1,
      });
      tl.to(
        ".overlay-bar",
        0.9,
        {
          delay: 0.1,
          width: "100%",
          stagger: {
            amount: 0.5,
          },
          ease: "power4.inOut",
        },
        "=-0.3"
      )
        .to(".generic__mount__domain", 0.4, {
          opacity: 1,
        })
        .to(
          ".overlay-bar",
          0.9,
          {
            delay: 0.1,
            transform: "translateX(100%)",
            stagger: {
              amount: 0.5,
            },
            ease: "power4.inOut",
          },
          "=-1"
        )
        .to(".overlay-rap", 0, {
          height: 0,
          opacity: 0,
          // transform: "scale(0)",
          display: "none",
        })
        .to(".overlay-bar", 0, {
          width: 0,
          transform: "translateX(0)",
        });
      // return navigateTo(to.path);
    }
  }
  // close the product modal if its open
  emit("closeProductViewModal");

  if (
    process.client &&
    appResourceStore.oldRouteVal &&
    appResourceStore.viewportWidth > 1306
  ) {
    const excludeRoutes = ["/auth/", "/account"];
    const isIncludesTo = excludeRoutes.filter((el: string) => {
      return to.path.includes(el);
    });
    const isIncludesFrom = excludeRoutes.filter((el: string) => {
      return from.path.includes(el);
    });
    if (isIncludesTo.length) {
      setTimeout(() => {
        gsap.to(".generic__mount__domain", 0, {
          opacity: 1,
        });
      }, 200);
    } else if (isIncludesFrom.length && !isIncludesTo.length) {
      setTimeout(() => {
        gsap.to(".generic__mount__domain", 0, {
          opacity: 1,
        });
        performTransition();
      }, 200);
    } else {
      performTransition();
    }
    // if (from.path.includes("/auth")) {
    //   setTimeout(() => {
    //     performTransition();
    //   }, 200);
    //   return;
    // }
  } else if (process.client) {
    setTimeout(() => {
      gsap.to(".generic__mount__domain", 0, {
        opacity: 1,
      });
      appResourceStore.oldRouteVal = to.path;
    }, 200);
  }
});
