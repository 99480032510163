export default {
  user: {
    id: "9b57c550-7752-4470-8e44-a2d9698674bb-3232",
    uuid: 0,
    first_name: "Beezlinq",
    last_name: "User",
    full_name: "Beezlinq User",
    username: "beez-user",
    email: "noreply@beezlinq.com",
    free_trial_status: "currently_on",
    total_scans_used: 0,
    total_scans_purchased: 0,
    scan_bonus_from_subscription: 0,
    total_scans_remaining: 0,
    cummulative_scans: 0,
    connection_count: 0,
    phone_number: "11111111",
    country: "Nigeria",
    state: "Federal Capital Territory",
    city: "Abuja",
    street_address: null,
    full_address:
      "9 Star Lake street, Maitama, Abuja 900271, Federal Capital Territory, Nigeria",
    postal_code: "900271",
    longitude: 7.4956326,
    latitude: 9.088952999999998,
    full_address_url: "https://maps.google.com/?cid=3658452452198643264",
    avatar: "http://api-dev.beezlinq.com/images/user-avatar-placeholder.jpg",
    phone_code: "+234",
    country_code: "234",
    is_first_time: 1,
    email_verified_at: "2023-08-07T14:24:28.000000Z",
    password_updated_at: "2023-12-14 09:28:08",
    is_staff: 0,
    active_subscription_plan_name: "Free",
    active_subscription_start_date: "2024-05-13 15:37:19",
    active_subscription_end_date: "2025-05-13 15:37:19",
    active_subscription_duration: "annual",
    subscriptions: {
      id: 2432,
      amount: "69.86",
      duration: "annual",
      start_date: "2024-05-13T14:37:19.000000Z",
      end_date: "2025-05-13T14:37:19.000000Z",
      on_free_trial: 0,
      status: "active",
      location_details: null,
      plan: {
        id: 5,
        name: "Free",
        card_quantity: "50000",
        scan_quantity: "20",
        base_amount: "6.99",
        monthly_amount: "6.99",
        yearly_amount: "64.99",
        monthly_discount: null,
        yearly_discount: null,
        discount_amount: null,
        discount_percent: null,
        yearly_discount_percent: "22.50",
        monthly_discount_percent: null,
        currency: "USD",
        plan_features: {},
        plan_features_details: [],
        plan_features_details_monthly: [],
        plan_features_details_annually: [],
        location_details: null,
      },
      in_app_plan: null,
    },
    interests: [],
    scan_subscriptions: null,
    system_language: {
      id: 1,
      name: "English",
      abbr: "en",
      status: 1,
      flag: "http://api-dev.beezlinq.com/images/flags/gb.png",
    },
    user_settings: {
      id: 1938,
      user_id: 0,
      two_factor_auth: 0,
      push_notifications: 0,
      biometrics_authentication: 0,
    },
    payment_cards: [],
  },
  categories: [
    {
      title: "Skin Care",
      avatar: "/images/temp/cat_1.png",
      subCategories: [
        "Body Cream",
        "Moisturizers",
        "Body Wash",
        "Sun Screens",
        "Brightening",
        "Acne & Blemish",
        "Hyper Pigmentation",
      ],
    },
    {
      title: "Personal Care",
      avatar: "/images/temp/cat_2.png",
      subCategories: [
        "Body Cream",
        "Moisturizers",
        "Body Wash",
        "Sun Screens",
        "Brightening",
        "Acne & Blemish",
        "Hyper Pigmentation",
      ],
    },
    {
      title: "Makeup",
      avatar: "/images/temp/cat_3.png",
      subCategories: [
        "Body Cream",
        "Moisturizers",
        "Body Wash",
        "Sun Screens",
        "Brightening",
        "Acne & Blemish",
        "Hyper Pigmentation",
      ],
    },
    {
      title: "Hair Care",
      avatar: "/images/temp/cat_1.png",
      subCategories: [
        "Body Cream",
        "Moisturizers",
        "Body Wash",
        "Sun Screens",
        "Brightening",
        "Acne & Blemish",
        "Hyper Pigmentation",
      ],
    },
    {
      title: "Spar and Salon",
      avatar: "/images/temp/cat_2.png",
      subCategories: [
        "Body Cream",
        "Moisturizers",
        "Body Wash",
        "Sun Screens",
        "Brightening",
        "Acne & Blemish",
        "Hyper Pigmentation",
      ],
    },
  ],
  product: [
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_1.png",
      type: "Body Wash",
      amount: 16000,
      rating: 4,
      review_count: 266,
      added_count: 0,
      outOfStock: true,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_2.png",
      type: "Body Wash",
      amount: 29000,
      rating: 5,
      review_count: 12,
      added_count: 1,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_3.png",
      type: "Body Wash",
      amount: 19000,
      rating: 1,
      review_count: 1270,
      outOfStock: true,
      added_count: 0,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_4.png",
      type: "Body Wash",
      amount: 19000,
      rating: 2,
      review_count: 12700,
      added_count: 0,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_5.png",
      type: "Body Wash",
      amount: 19000,
      rating: 4,
      review_count: 127300,
      added_count: 10,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_6.png",
      type: "Body Wash",
      amount: 19000,
      rating: 4,
      review_count: 1270100,
      added_count: 10,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_7.png",
      type: "Body Wash",
      amount: 19000,
      rating: 4,
      review_count: 12,
      added_count: 10,
    },
    {
      title: "This is where the title of cosmetics product displays",
      avatar: "/images/temp/product_8.png",
      type: "Body Wash",
      amount: 20,
      rating: 4,
      review_count: 126,
      added_count: 0,
      outOfStock: true,
    },
  ],
};
