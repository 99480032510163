import { default as about_45us3WINtjw9M8Meta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/about-us.vue?macro=true";
import { default as address_45bookwVvSfduYqnMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/address-book.vue?macro=true";
import { default as indexNTMMCK0H7LMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/index.vue?macro=true";
import { default as contact_45supportQRksJz6O9lMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/contact-support.vue?macro=true";
import { default as indexb0HCuN0aJoMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/index.vue?macro=true";
import { default as view_45order_45detailsT2Q6tQThFLMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/view-order-details.vue?macro=true";
import { default as profilebtmS9SlHJyMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/profile.vue?macro=true";
import { default as saved_45cardssZnlJNPMmBMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/saved-cards.vue?macro=true";
import { default as transaction_45historyGAkOvMBIybMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/transaction-history.vue?macro=true";
import { default as walletajLTQDrbeRMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/wallet.vue?macro=true";
import { default as accountibAI2EQTiFMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account.vue?macro=true";
import { default as forgot_45passwordtObtbRSFIqMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/forgot-password.vue?macro=true";
import { default as loginZAokO2f1m3Meta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/login.vue?macro=true";
import { default as registerayY4JRWooHMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/register.vue?macro=true";
import { default as contact_45usICH3WgcovcMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/contact-us.vue?macro=true";
import { default as frequently_45asked_45questionsw29S4vh2VKMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/frequently-asked-questions.vue?macro=true";
import { default as indexOzG6hZAjpaMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/index.vue?macro=true";
import { default as privacy_45policyuWkbY7LGAhMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/privacy-policy.vue?macro=true";
import { default as _91sub_45category_93D3rSKlQcXJMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category]/[sub-category].vue?macro=true";
import { default as index0ORBKT9l4EMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category]/index.vue?macro=true";
import { default as _91category_93YDY0FxxGmOMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category].vue?macro=true";
import { default as indexa0YCO9Nf7RMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/index.vue?macro=true";
import { default as searchDYHgwxzWf2Meta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search.vue?macro=true";
import { default as index0qdSoHfDngMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/shopping-cart/index.vue?macro=true";
import { default as order_45summaryhHqI41JblyMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/shopping-cart/order-summary.vue?macro=true";
import { default as singleEMk5MitmF0Meta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/single.vue?macro=true";
import { default as terms_45and_45conditionsJJzcNlfnfbMeta } from "C:/Users/davie/Documents/GitHub/korner/korner - user/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: accountibAI2EQTiFMeta?.name,
    path: "/account",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-address-book",
    path: "address-book",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/address-book.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-contact-support",
    path: "orders/contact-support",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/contact-support.vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-view-order-details",
    path: "orders/view-order-details",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/orders/view-order-details.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-saved-cards",
    path: "saved-cards",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/saved-cards.vue").then(m => m.default || m)
  },
  {
    name: "account-transaction-history",
    path: "transaction-history",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "account-wallet",
    path: "wallet",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/account/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordtObtbRSFIqMeta || {},
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginZAokO2f1m3Meta || {},
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerayY4JRWooHMeta || {},
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "frequently-asked-questions",
    path: "/frequently-asked-questions",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/frequently-asked-questions.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: searchDYHgwxzWf2Meta?.name,
    path: "/search",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93YDY0FxxGmOMeta?.name,
    path: ":category()",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category].vue").then(m => m.default || m),
    children: [
  {
    name: "search-category-subcategory",
    path: ":subcategory()",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category]/[sub-category].vue").then(m => m.default || m)
  },
  {
    name: "search-category",
    path: "",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/[category]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search",
    path: "",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "shopping-cart",
    path: "/shopping-cart",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/shopping-cart/index.vue").then(m => m.default || m)
  },
  {
    name: "shopping-cart-order-summary",
    path: "/shopping-cart/order-summary",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/shopping-cart/order-summary.vue").then(m => m.default || m)
  },
  {
    name: "single",
    path: "/single",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/single.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("C:/Users/davie/Documents/GitHub/korner/korner - user/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]